import React, { useContext, useEffect, useState } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import LPContext from '@jsv2/LandingPage/context/LPContext';

import { getReviewListApi } from '@jsv3/utils/api/reviewListAPI';
import ReviewsGallery from '@jsv3/components/molecules/ReviewsGallery';

/**
 * Render reviews in a slider
 *
 * @param
 *
 * @return {*}
 *
 * @constructor
 */
const ReviewsWithBorder = () => {
  const { editableFields } = useContext(EditFieldContext);
  const { renderBecomeButton } = useContext(LPContext);

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getReviewListApi({ internal: 1 }).then(({ data }) => {
      setReviews(data || []);
    });
  }, []);

  return (
    reviews.length > 0 && (
      <div className="reviews-with-border">
        <div className="main-container">
          <div className="section-title section-title--with-underline section-title--center">
            <Editable fieldValue={editableFields.reviews_title} />
          </div>

          <ReviewsGallery reviews={reviews} />

          {renderBecomeButton(
            'become-btn become-btn--red',
            window.templateVariables.body_cta_text.content,
            'become-join-btn-1',
          )}
        </div>
      </div>
    )
  );
};

export default ErrorBoundaryDecorator()(ReviewsWithBorder);
