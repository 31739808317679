import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, {
  Editable,
  EditableImg,
} from '@jsv2/LandingPage/context/EditFieldContext';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import Quote from './Quote';

const NUMBER_OF_VISIBLE_BENEFITS = 3;

const LargeBenefitsSection = React.forwardRef(({ id }, ref) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const { renderBecomeButton } = useContext(LPContext);

  const benefits = [
    {
      id: 1,
      title: editableFields.large_benefits_item_1_title,
      content: editableFields.large_benefits_item_1_content,
      image: editableImageFields.large_benefits_image_1,
    },
    {
      id: 2,
      title: editableFields.large_benefits_item_2_title,
      content: editableFields.large_benefits_item_2_content,
      image: editableImageFields.large_benefits_image_2,
    },
    {
      id: 3,
      title: editableFields.large_benefits_item_3_title,
      content: editableFields.large_benefits_item_3_content,
      image: editableImageFields.large_benefits_image_3,
    },
    {
      id: 4,
      title: editableFields.large_benefits_item_4_title,
      content: editableFields.large_benefits_item_4_content,
      image: editableImageFields.large_benefits_image_4,
    },
    {
      id: 5,
      title: editableFields.large_benefits_item_5_title,
      content: editableFields.large_benefits_item_5_content,
      image: editableImageFields.large_benefits_image_5,
    },
    {
      id: 6,
      title: editableFields.large_benefits_item_6_title,
      content: editableFields.large_benefits_item_6_content,
      image: editableImageFields.large_benefits_image_6,
    },
  ];

  /**
   * @param items
   * @return {React.JSX.Element}
   */
  const renderBenefits = (items) => (
    <div className="large-benefits__list">
      {items.map((benefit) => (
        <div key={benefit.id} className="large-benefits__list-item">
          <div className="large-benefits__list-item-image">
            {benefit.image && (
              <EditableImg image={benefit.image} width={450} height={450}>
                <Image
                  data={benefit.image && benefit.image.content}
                  type="PageContent"
                  config={{
                    size: IMAGE_SIZES.IMAGE_W350,
                  }}
                  background
                />
              </EditableImg>
            )}
          </div>

          <div className="large-benefits__list-item-body">
            <div className="section-title-small section-title-small--with-underline section-title-small--left">
              <Editable fieldValue={benefit.title} />
            </div>

            <div className="large-benefits__list-item-content">
              <Editable fieldValue={benefit.content} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    editableFields && (
      <section id={id} ref={ref} className="large-benefits pt-50 pb-50">
        <div className="small-container">
          <div className="section-title section-title--with-underline section-title--center">
            <Editable fieldValue={editableFields.large_benefits_heading} />
          </div>

          <div className="section-description section-description--center">
            <Editable fieldValue={editableFields.large_benefits_tagline} />
          </div>

          {renderBenefits(benefits.slice(0, NUMBER_OF_VISIBLE_BENEFITS))}

          {renderBecomeButton(
            'become-btn become-btn--with-border',
            window.templateVariables.body_cta_text.content,
            'benefits-cta-1',
          )}

          <Quote id="quote_in_benefits" quoteVariable="quote_content" />

          {renderBenefits(benefits.slice(-NUMBER_OF_VISIBLE_BENEFITS))}

          {renderBecomeButton(
            'become-btn become-btn--with-border',
            window.templateVariables.body_cta_text.content,
            'benefits-cta-2',
          )}
        </div>
      </section>
    )
  );
});

LargeBenefitsSection.displayName = 'LargeBenefitsSection';

export default ErrorBoundaryDecorator()(LargeBenefitsSection);
