import Logger from 'js-logger';
import _ from 'lodash';

import STATUS_CODES from '@jsv2/config/httpStatusCodes';
import apiClient from '../apiClient';

window.Logger = Logger;

const logLevelsMap = {
  TRACE: 'debug',
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warning',
  ERROR: 'error',
};

if (window.IS_PRODUCTION) {
  Logger.setLevel(Logger.WARN);
  Logger.setHandler(([...messages], context) => {
    const level = logLevelsMap[context.level.name];
    let message = messages[0];

    if (message.response && message.response.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
      return;
    }

    if (!_.isString(message)) {
      message = level;
    } else {
      messages.shift();
    }

    messages.forEach((value, index) => {
      if (value.error) {
        messages[index].error = { message: value.error.message, stack: value.error.stack };
      }
    });

    apiClient.post('/logs', {
      message,
      additionalData: messages,
      level,
    });
  });
} else if (process.env.NODE_ENV === 'test') {
  Logger.setLevel(Logger.error);
  // eslint-disable-next-line no-console
  console.warn('Be aware that window.Logger messages are disabled in test env');
  Logger.setHandler(() => {});
} else {
  Logger.useDefaults();
}
