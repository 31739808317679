import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import { getCurrentScreenType } from '@js/utils/deviceWidthDetector';
import ScreenTypeContext from '@js/context/ScreenTypeContext';
import '@js/polyfills';
import '@js/utils/logger-init';
import '@js/feature-detector';
import '@js/langs-setup';
import ActivityDaemon from '@js/services/ActivityDaemon';
import hashChangeListener from '@js/services/hashChangeListener';
import StartupNotifications from '@js/services/StartupNotifications';

import configureStore from '@js/configureStore';
import AdProcessor from '@js/AdProcessor';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import sessionStorageUtils from '@js/sessionStorageUtils';

// export store to be able to set some page-specific state in parent component
export const store = configureStore();

sessionStorageUtils.init();

new ActivityDaemon().run();
new StartupNotifications().run();
hashChangeListener.init();

/**
 * This component contains regular page layout.
 * Please make sure you IMPORT IT BEFORE ANY OTHER COMPONENT.
 * It's important coz logger and polyfills will not be enabled otherwise
 */
@ErrorBoundaryDecorator(true)
class App extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: 'app',
  };

  state = {
    screenType: getCurrentScreenType(),
  };

  componentDidMount() {
    this.setScreenHeight();
    this.debouncedResizeHandler = _.debounce(this.onResize, 250);

    window.addEventListener('resize', this.debouncedResizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResizeHandler);
  }

  onResize = () => {
    const screenType = getCurrentScreenType();
    if (screenType !== this.state.screenType) {
      this.setState({ screenType });
    }
    this.setScreenHeight();
  };

  /**
   * Add browser screen size by height to css variable (used in css).
   */
  setScreenHeight = () => {
    const screenHeight = window.innerHeight + 'px';
    const rootPageElement = document.getElementsByTagName('html')[0] || null;

    if (rootPageElement) {
      rootPageElement.style.setProperty('--screen-height', screenHeight);
    }
  };

  render() {
    const { screenType } = this.state;
    const { className } = this.props;

    return (
      <Provider store={store}>
        <ScreenTypeContext.Provider value={screenType}>
          <div id="app-root" className={classNames(className)}>
            <div className="content">{this.props.children}</div>

            <AdProcessor />
          </div>
        </ScreenTypeContext.Provider>
      </Provider>
    );
  }
}

export default App;
